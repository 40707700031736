<template>
    <slot/>
</template>

<script setup>
useHead({
    htmlAttrs: {
        // class: 'dark'
    }
})
</script>

<style lang="scss">
@import '~/assets/css/editor.scss';
</style>